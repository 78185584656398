import { template as template_22665c94bbc8489c9d9bd38d775369de } from "@ember/template-compiler";
const FKText = template_22665c94bbc8489c9d9bd38d775369de(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
