import { template as template_0ec103d4ca2747b0a4633202f33764e1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_0ec103d4ca2747b0a4633202f33764e1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
