import { template as template_3d38b8e46c194851b461cf65e5d31152 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3d38b8e46c194851b461cf65e5d31152(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
